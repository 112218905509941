import { useState, useEffect } from 'react';

import Hamburger from 'hamburger-react';
import { AppBar, Box, IconButton, Toolbar, Typography, useTheme } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SearchIcon from '@mui/icons-material/Search';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PhoneIcon from '@mui/icons-material/Phone';

import { App, Maybe, NavigationLink, Page } from '@/types/generated';
import ImageWrapper from '@/components/ImageWrapper';
import TAGLink from '@/components/TAGLink';
import TAGButton from '@/components/TAGButton';
import TAGSvgIcon from '@/components/TAGSvgIcon/TAGSvgIcon';
import { useAppContext, useFacilityContext } from '@/context';
import HeaderWithLocationNavMobile from '@/components/HeaderWithLocationNav/HeaderMobile';
import LanguageToggle from '@/components/LanguageToggle';
import { clickContext } from '@/utils';

import OfficeInformationBlock from '../HeaderOfficeLocation/HeaderOfficeDetails/OfficeInformationBlock';
import useDisappearingHeader from '../hooks/useDissappearingHeader';
import NavigationBar from '../NavigationBar';
import { ODPLinksBlock, LocationLinksBlock } from '../HeaderOfficeLocation/HeaderOfficeDetails/HeaderOfficeDetails';
import { popupSchedulingStyles, locationIconStyles } from '../HeaderSearch/HeaderSearch.styles';
import NavigationMenu from '../NavigationMenu/NavigationMenu';

import {
	getWrapperStyles,
	logoStyles,
	iconStyles,
	scheduleButtonStyles,
	toolbarHeaderStyles,
	toolbarActionsStyles,
	burgerAndLogoWrapperStyles,
	navMenuOfficeDetailsStyles,
	menuIconStyles,
	menuLinkStyles,
	getNavMenuNavigationBarStyles,
	menuCTAStyles,
	scheduleApptLinkStyles,
	locationNameToolbarStyles,
} from './HeaderMobile.styles';
import MobileMenu from './MobileMenu';

export interface IHeaderMobile {
	headerData: App['header'];
	logoUrl?: Maybe<string>;
	withOfficeDetails?: boolean;
	isFullScreenMenu?: boolean;
	withMenuCTA?: boolean;
	brand?: Maybe<string>;
	hidePrimaryActionButton: boolean;
	hideLocation: boolean;
	showHeaderNavMenu?: boolean;
	logoHref: string;
	locationNavigationLinks?: Array<NavigationLink | Page> | undefined;
	phoneNumber?: Maybe<NavigationLink>;
	withFixedHeader?: boolean;
	showMobileHeaderLocationBar?: boolean;
	showLanguageToggle?: boolean;
	hideHamburgerMenuToggleButton?: boolean;
}

export default function HeaderMobile({
	headerData,
	logoUrl,
	withOfficeDetails = false,
	isFullScreenMenu = true,
	withMenuCTA = true,
	hidePrimaryActionButton,
	hideLocation,
	showHeaderNavMenu = false,
	logoHref = '/',
	locationNavigationLinks,
	phoneNumber,
	withFixedHeader = false,
	showMobileHeaderLocationBar = true,
	showLanguageToggle,
	hideHamburgerMenuToggleButton = false,
}: IHeaderMobile) {
	const theme = useTheme();
	// maybe disable this somehow for brands that do not use facilities
	const { officeInfo, locationState } = useFacilityContext();
	const { config } = useAppContext();
	const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
	const [isLocMenuOpen, setIsLocMenuOpen] = useState(false);
	const headerElement = useDisappearingHeader({ hiddenPos: '-116px', showingPos: '0', withFixedHeader });
	const [menuAnchorEl, setMenuAnchorEl] = useState<
		(HTMLDivElement & { getBoundingClientRect: () => { height: number } }) | null
	>(null);
	const [isPopScheduleMenuOpen, setIsPopScheduleMenuOpen] = useState<boolean>(false);

	const utilityNavigation = headerData?.topNavigationLinksCollection;

	const phoneNumberNavItem = headerData?.topNavigationLinksCollection?.items
		?.map((item) => {
			if (
				item?.__typename === 'NavigationLink' &&
				item?.externalUrl?.includes('tel:') &&
				item?.linkText !== undefined
			) {
				return item;
			}
			return undefined;
		})
		.find((linkText) => linkText !== undefined);

	const determinePhoneNumber = (
		phoneNumberNavItem: NavigationLink | undefined,
		phoneNumber: Maybe<NavigationLink> | undefined
	): string => {
		if (phoneNumberNavItem && phoneNumberNavItem.linkText !== undefined) {
			return phoneNumberNavItem.linkText || '#';
		} else if (phoneNumber && phoneNumber.linkText !== undefined) {
			return phoneNumber.linkText || '#';
		}
		return '#';
	};
	const showHeaderWithLocationNav = config.featureFlags.showHeaderWithLocationNav;

	useEffect(() => {
		if (isNavMenuOpen) {
			document.documentElement.style.overflowY = 'hidden';
		} else {
			document.documentElement.style.overflowY = 'inherit';
		}
	}, [isNavMenuOpen]);

	const handleLocationLinkClick = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();
		event.stopPropagation();

		if (withOfficeDetails) {
			setIsLocMenuOpen(true);
			setIsNavMenuOpen(false);
		} else if (headerData?.locationBarFindOfficeUrl) {
			globalThis.location.href = headerData.locationBarFindOfficeUrl;
		}
	};

	const SearchButton = () => {
		return (
			<IconButton
				color="inherit"
				href={headerData?.locationBarFindOfficeUrl || '#'}
				sx={locationIconStyles.svgSize}
				data-test-id="button_search_icon_mobile"
			>
				<SearchIcon data-test-id="icon_search_on" />
			</IconButton>
		);
	};

	const SchedulingButtonMobile = () => {
		if (!headerData?.usePopupScheduling) {
			return (
				<TAGButton
					size="S"
					variant="primaryAccent1"
					sx={scheduleButtonStyles}
					href={
						headerData?.schedulingButtonUrlMobile ||
						headerData?.schedulingButtonUrl ||
						'/schedule-an-appointment'
					}
					dataTestId="button_schedule_mobile"
					analyticsContext={clickContext({ brand: config.name, type: 'scheduleClick' })}
				>
					{headerData?.schedulingButtonTextMobile || headerData?.schedulingButtonText || 'Schedule'}
				</TAGButton>
			);
		}
		return (
			<TAGButton
				size="S"
				variant="primaryAccent1"
				sx={scheduleButtonStyles}
				onClick={() => setIsPopScheduleMenuOpen(true)}
				dataTestId="button_schedule_mobile_popup"
			>
				{headerData?.schedulingButtonTextMobile || headerData?.schedulingButtonText || 'Schedule'}
			</TAGButton>
		);
	};

	const SchedulingButtonMobileMenu = () => {
		if (!headerData?.usePopupScheduling) {
			return (
				<TAGButton
					size="M"
					fullWidth
					variant="primaryDefault"
					href={
						headerData?.schedulingButtonUrlMobile ||
						headerData?.schedulingButtonUrl ||
						'/schedule-an-appointment'
					}
					dataTestId="button_schedule_mobile"
					sx={{ backgroundColor: 'secondary.main', mb: '0.75rem' }}
				>
					<CalendarTodayIcon sx={menuIconStyles} />
					{headerData?.schedulingButtonText || 'Schedule appointment'}
				</TAGButton>
			);
		}
		return (
			<TAGButton
				size="M"
				fullWidth
				variant="primaryDefault"
				onClick={() => setIsPopScheduleMenuOpen(true)}
				dataTestId="button_schedule_mobile_popup"
				sx={{ backgroundColor: 'secondary.main', mb: '0.75rem' }}
			>
				<CalendarTodayIcon sx={menuIconStyles} />
				{headerData?.schedulingButtonText || 'Schedule appointment'}
			</TAGButton>
		);
	};

	const PopupSchedulingMenu = () => {
		const toggleMenu = () => {
			setIsPopScheduleMenuOpen(false);
			setIsNavMenuOpen(false);
		};
		return (
			<MobileMenu
				open={isPopScheduleMenuOpen}
				onClose={() => setIsPopScheduleMenuOpen(false)}
				isFullScreenMenu={isFullScreenMenu}
				anchorEl={menuAnchorEl}
			>
				<Box display="flex" flexDirection="column" sx={popupSchedulingStyles.containerStyles}>
					<Typography variant="header3" sx={popupSchedulingStyles.headerStyles}>
						{headerData?.schedulingButtonTextMobile}
					</Typography>
					{headerData?.schedulingNavigationLinksCollection?.items.map((navItem, index) =>
						navItem ? (
							<Box key={index} sx={popupSchedulingStyles.navigationItemStyles}>
								<Typography
									variant="bodyLargeSemiBold"
									color="text.secondary"
									sx={popupSchedulingStyles.groupLinkLinkTextStyles}
								>
									{navItem.groupLink?.linkText}
								</Typography>
								{navItem.linksCollection?.items.map((link, index) => (
									<TAGLink
										key={index}
										onClick={toggleMenu}
										href={
											link?.isInternal
												? `${link?.pageData?.path as string}`
												: `${link?.externalUrl as string}`
										}
										noLinkStyle
										analyticsContext={link?.analyticsContext}
									>
										<Typography variant="bodyLargeSemiBold" color="text.interactive">
											{link?.linkText}
										</Typography>
									</TAGLink>
								))}
							</Box>
						) : null
					)}
				</Box>
			</MobileMenu>
		);
	};

	if (showHeaderWithLocationNav) {
		return <HeaderWithLocationNavMobile headerData={headerData} logoHref={logoHref} logoUrl={logoUrl} />;
	}

	return (
		<AppBar
			ref={headerElement}
			sx={getWrapperStyles(theme, showMobileHeaderLocationBar)}
			data-test-id="section_header_mobile"
		>
			<Toolbar
				disableGutters
				sx={toolbarHeaderStyles}
				ref={(el) => setMenuAnchorEl(el)}
				data-test-id="section_header_actions"
			>
				<Box sx={toolbarActionsStyles} data-test-id="location_box_header_toolbar_root">
					<Box sx={burgerAndLogoWrapperStyles}>
						{!hideHamburgerMenuToggleButton && (
							<IconButton sx={iconStyles} data-test-id="button_hamburger" tabIndex={-1}>
								<Hamburger
									size={24}
									toggled={isNavMenuOpen || isLocMenuOpen}
									toggle={setIsNavMenuOpen}
									color={theme.palette.text.interactive}
									label="open menu"
								/>
							</IconButton>
						)}
						<TAGLink href={logoHref}>
							<ImageWrapper
								src={logoUrl}
								width="116px" // todo - add prop to set width from contentful
								height="auto"
								sx={logoStyles}
								data-test-id="img_logo_mobile"
								alt="brand logo"
							/>
						</TAGLink>
					</Box>
					{showLanguageToggle && <LanguageToggle isLargeDevice />}
					<Box display="flex" justifyContent="flex-end" alignItems="center">
						{hideLocation ? null : <SearchButton />}
						{hidePrimaryActionButton ? null : <SchedulingButtonMobile />}
						<PopupSchedulingMenu />
					</Box>
				</Box>
				{showMobileHeaderLocationBar ? (
					<Box sx={locationNameToolbarStyles(theme)} data-test-id="location_box_header_toolbar">
						<TAGButton
							size="S"
							variant="tertiaryHC"
							href={headerData?.locationBarFindOfficeUrl || '#'}
							startIcon={
								<TAGSvgIcon icon="LocationOn" size={25} dataTestId={`start_icon_location_button`} />
							}
						>
							{officeInfo?.name
								? officeInfo.name
								: headerData?.locationBarWithOutOfficeTitle || 'Find an office'}
						</TAGButton>
					</Box>
				) : null}
			</Toolbar>

			<MobileMenu
				testIdSuffix="nav_menu"
				anchorEl={menuAnchorEl}
				open={isNavMenuOpen}
				onClose={() => setIsNavMenuOpen(false)}
				isFullScreenMenu={isFullScreenMenu}
				isWithNavMenu={showHeaderNavMenu}
			>
				{((withOfficeDetails && !withMenuCTA) || showHeaderNavMenu) && (
					<Box sx={navMenuOfficeDetailsStyles}>
						<TAGLink onClick={handleLocationLinkClick} dataTestId="link_facility_name">
							<LocationOnOutlinedIcon sx={menuIconStyles} />

							<Typography
								sx={menuLinkStyles}
								variant="bodyMediumBold"
								color="text.primary"
								data-test-id="text_facility_name"
							>
								{officeInfo?.name
									? officeInfo.name
									: headerData?.locationBarWithOutOfficeTitle || 'Find an office'}
							</Typography>
						</TAGLink>

						{headerData?.profileLinkUrl && (
							<TAGLink href={headerData.profileLinkUrl}>
								<PersonOutlineIcon sx={menuIconStyles} />
								<Typography
									sx={menuLinkStyles}
									variant="bodyMediumBold"
									color="text.primary"
									data-test-id="text_user_profile_mobile"
								>
									{headerData.myAccountLabel || 'My account'}
								</Typography>
							</TAGLink>
						)}

						<TAGLink
							href={headerData?.schedulingButtonUrl || '/schedule-an-appointment'}
							linkSx={scheduleApptLinkStyles}
						>
							<CalendarTodayIcon sx={menuIconStyles} />
							<Typography
								sx={menuLinkStyles}
								variant="bodyMediumBold"
								color="text.primary"
								data-test-id="text_schedule_link_mobile"
							>
								{headerData?.schedulingButtonText || 'Schedule appointment'}
							</Typography>
						</TAGLink>

						{phoneNumberNavItem || phoneNumber ? (
							<TAGLink
								noLinkStyle
								href={`tel:${determinePhoneNumber(phoneNumberNavItem, phoneNumber)}`}
								linkSx={scheduleApptLinkStyles}
							>
								<PhoneIcon sx={menuIconStyles} />
								<Typography
									sx={menuLinkStyles}
									variant="bodyMediumBold"
									color="text.primary"
									data-test-id="text_phone_link_mobile"
								>
									{determinePhoneNumber(phoneNumberNavItem, phoneNumber)}
								</Typography>
							</TAGLink>
						) : null}
					</Box>
				)}

				{showHeaderNavMenu ? (
					<NavigationMenu
						onClose={() => setIsNavMenuOpen(false)}
						isSmallScreen
						mainNavItems={headerData?.navigationMenu?.headerMainNavLinksCollection}
					/>
				) : (
					<NavigationBar
						onClose={() => setIsNavMenuOpen(false)}
						isSmallScreen
						navigationItems={headerData?.navigationLinksCollection}
						navigationSx={getNavMenuNavigationBarStyles(withOfficeDetails)}
					/>
				)}
				{!!utilityNavigation?.items?.length && !showHeaderNavMenu && (
					<NavigationBar
						testIdSuffix="utility"
						onClose={() => setIsNavMenuOpen(false)}
						isSmallScreen
						navigationItems={utilityNavigation}
						navigationSx={getNavMenuNavigationBarStyles(withOfficeDetails)}
						isUtilityNavigation
					/>
				)}
				{withMenuCTA && !showHeaderNavMenu && (
					<Box sx={menuCTAStyles}>
						<SchedulingButtonMobileMenu />
						<TAGButton
							size="M"
							fullWidth
							variant="secondaryDefault"
							onClick={handleLocationLinkClick}
							dataTestId="button_find_another_office_mobile"
						>
							<LocationOnOutlinedIcon sx={menuIconStyles} />
							{officeInfo?.name
								? officeInfo.name
								: headerData?.locationBarWithOutOfficeTitle || 'Find an office'}
						</TAGButton>
					</Box>
				)}
			</MobileMenu>

			<MobileMenu
				testIdSuffix="location_menu"
				anchorEl={menuAnchorEl}
				open={isLocMenuOpen}
				onClose={() => setIsLocMenuOpen(false)}
				isFullScreenMenu={isFullScreenMenu}
				isWithNavMenu={showHeaderNavMenu}
				transitionDuration={isNavMenuOpen ? 0 : 'auto'}
			>
				{officeInfo && (
					<Box display="flex" flexDirection="column">
						<Box padding="1.375rem 2.75rem 2rem 1.25rem">
							<OfficeInformationBlock office={officeInfo} distance={locationState?.distance} invert />
						</Box>
						{officeInfo && locationNavigationLinks ? (
							<LocationLinksBlock
								isSmallScreen
								locationNavigationLinks={locationNavigationLinks}
								officeInfo={officeInfo}
							/>
						) : officeInfo ? (
							<ODPLinksBlock isSmallScreen officeInfo={officeInfo} />
						) : null}
					</Box>
				)}
				<Box padding="1.75rem 1.3rem" borderBottom="1px solid" borderColor="neutrals.primary">
					<TAGButton
						size="M"
						fullWidth
						variant="secondaryDefault"
						href={headerData?.locationBarFindOfficeUrl || '#'}
						dataTestId="button_find_another_office_mobile"
					>
						{headerData?.locationBarWithOfficeTitle || 'Find Another Office'}
					</TAGButton>
				</Box>
			</MobileMenu>
		</AppBar>
	);
}
